import React from "react"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

class Toastr extends React.Component {
    notifyDefault = () => toast("This is default toast!")
    notifySuccess = () => toast.success("This is success toast!")
    notifyError = () => toast.error("This is error toast!")
    notifyInfo = () => toast.info("This is info toast!")
    notifyWarning = () => toast.warning("This is warning toast!")
    notifyA = (message, type) => {

        if (type == 'error') {

            toast.error(`${message}`, {containerId: 'A'})

        } else {
            if (type != 'timedout') {
                toast.success(`${message}`, {containerId: 'A'})
            }
        }

        console.log(type)
    };

    componentDidMount() {
        this.notifyA(this.props.message, this.props.type)
    }

    render() {
        return (
            <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT}/>
            </div>
        )
    }
}

export default Toastr
