import React from "react"
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    Label
} from "reactstrap"
import fgImg from "../../../../assets/img/pages/forgot-password.png"
import "../../../../assets/scss/pages/authentication.scss"
import classnames from "classnames";
import themeConfig from "../../../../configs/themeConfig";
import "../../../../components/@vuexy/rippleButton/RippleButton"

class ForgotPassword extends React.Component {
    state = {
        email: ""
    }

    render() {
        return (
            <div
                className={classnames(
                    "full-layout wrapper blank-page dark-layout",
                    {
                        "layout-dark": themeConfig.layoutDark
                    }
                )}
            >
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="content-body">
                            <div className="flexbox-container">
                                <main className="main w-100">
                                    <Row className="m-0 justify-content-center">
                                        <Col
                                            sm="8"
                                            xl="7"
                                            lg="10"
                                            md="8"
                                            className="d-flex justify-content-center"
                                        >
                                            <Card className="bg-authentication rounded-0 mb-0 w-100">
                                                <Row className="m-0">
                                                    <Col
                                                        lg="6"
                                                        className="d-lg-block d-none text-center align-self-center"
                                                    >
                                                        <img src={fgImg} alt="fgImg"/>
                                                    </Col>
                                                    <Col lg="6" md="12" className="p-0">
                                                        <Card className="rounded-0 mb-0 px-2 py-1">
                                                            <CardHeader className="pb-1">
                                                                <CardTitle>
                                                                    <h4 className="mb-0">Recupera tu contraseña</h4>
                                                                </CardTitle>
                                                            </CardHeader>
                                                            <p className="px-2 auth-title">
                                                                Por favor ingresa tu email y te
                                                                enviaremos instrucciones sobre cómo restablecer tu
                                                                contraseña.
                                                            </p>
                                                            <CardBody className="pt-1 pb-0">
                                                                <FormGroup className="form-label-group">
                                                                    <Input
                                                                        type="email"
                                                                        placeholder="Email"
                                                                        name="collect_user[email]"
                                                                        id="collect_user_email"
                                                                        value={this.state.email}
                                                                        onChange={e => this.setState({email: e.target.value})}
                                                                        required
                                                                    />
                                                                    <Label>Email</Label>
                                                                </FormGroup>
                                                                <div
                                                                    className="float-md-left d-block mb-1">
                                                                    <Button.Ripple
                                                                        color="primary"
                                                                        outline
                                                                        className="px-75 btn-block"
                                                                        onClick={e => {
                                                                            e.preventDefault()
                                                                            window.location.replace(`${this.props.path}/login`);
                                                                        }}
                                                                    >
                                                                        Regresar
                                                                    </Button.Ripple>
                                                                </div>
                                                                <div
                                                                    className="float-md-right d-block mb-1">
                                                                    <Button.Ripple
                                                                        color="primary"
                                                                        type="submit"
                                                                        className="px-75 btn-block"

                                                                    >
                                                                        Recuperar Contraseña
                                                                    </Button.Ripple>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
