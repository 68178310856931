import React, {Suspense, lazy} from "react"
import {Provider} from "react-redux"
import {Layout} from "../../utility/context/Layout"
import {store} from "../../redux/storeConfig/store"
import Spinner from "../../components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import "../../@fake-db"
import {ActionCableProvider} from 'react-actioncable-provider'

//global variables
window.$api_path = '/api/v1/'
window.$role_user = document.getElementById('root').dataset.role
window.$qr_path = '/api/v1/'
window.$url_app = document.getElementById('root').dataset.url
window.$app_path = document.getElementById('root').dataset.attrs
window.$type_company = document.getElementById('root').dataset.type

const LazyApp = lazy(() => {
    return Promise.all([
        import("./App"),
        new Promise(resolve => setTimeout(resolve, 1500))
    ])
        .then(([moduleExports]) => moduleExports);
});

class Home extends React.Component {
    render() {
        return (
            <ActionCableProvider url={location.protocol == "http:" ? `ws://${window.$url_app}/cable` :`wss://${window.$url_app}/cable`}>

                <Provider store={store}>
                    <Suspense fallback={<Spinner/>}>
                        <Layout>
                            <LazyApp/>
                        </Layout>
                    </Suspense>
                </Provider>
            </ActionCableProvider>
        )
    }
}

export default Home
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
