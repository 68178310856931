import React, {Suspense, lazy} from "react"
import Spinner from "../../../@vuexy/spinner/Fallback-spinner"
import "../../../App/index.scss"


const LazyLayout = lazy(() => {
    return Promise.all([
        import("./ForgotPassword.js"),
        new Promise(resolve => setTimeout(resolve, 1500))
    ])
        .then(([moduleExports]) => moduleExports);
});

class Forgot extends React.Component {
    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <LazyLayout path={this.props.path}/>
            </Suspense>
        )
    }
}

export default Forgot

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
