import React from "react"
import {
    Button,
    FormGroup,
    Input,
    Label
} from "reactstrap"
import {Mail, Lock, Check, Facebook, Twitter, GitHub} from "react-feather"
import Checkbox from "../../../../components/@vuexy/checkbox/CheckboxesVuexy"
import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"

import "../../../../assets/scss/pages/authentication.scss"
import "../../../../components/@vuexy/rippleButton/RippleButton"

class FormLogin extends React.Component {
    state = {
        activeTab: "1",
        email: "",
        password: ""
    }

    render() {
        return (
            <div>
                <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                        type="email"
                        placeholder="Email"
                        name="collect_user[email]"
                        id="collect_user_email"
                        value={this.state.email}
                        onChange={e => this.setState({email: e.target.value})}
                    />
                    <div className="form-control-position">
                        <Mail size={15}/>
                    </div>
                    <Label>Email</Label>
                </FormGroup>
                <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                        type="password"
                        placeholder="Contraseña"
                        name="collect_user[password]"
                        id="collect_user_password"
                        value={this.state.password}
                        onChange={e => this.setState({password: e.target.value})}
                    />
                    <div className="form-control-position">
                        <Lock size={15}/>
                    </div>
                    <Label>Contraseña</Label>
                </FormGroup>
                <FormGroup className="d-flex justify-content-between align-items-center">
                    <Checkbox
                        name="collect_user[remember_me]"
                        id="collect_user_remember_me"
                        color="primary"
                        icon={<Check className="vx-icon" size={16}/>}
                        label="Recordar"
                    />
                    <div className="float-right">
                        <a href={`${this.props.path}/nuevo_password`}>Recuperar contraseña?</a>
                    </div>
                </FormGroup>
                <div className="d-flex justify-content-between">
                    <Button.Ripple color="primary" type="submit">
                        Acceder
                    </Button.Ripple>
                </div>
            </div>
        )
    }
}

export default FormLogin
