import React, {Suspense, lazy} from "react"
import Spinner from "../../../@vuexy/spinner/Fallback-spinner"
import "../../../App/index.scss"


const LazyLayout = lazy(() => {
    return Promise.all([
        import("./Layout.js"),
        new Promise(resolve => setTimeout(resolve, 1500))
    ])
        .then(([moduleExports]) => moduleExports);
});

class Logg extends React.Component {
    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <LazyLayout path={this.props.path}/>
            </Suspense>
        )
    }
}



export default Logg
