import React, {lazy} from "react"
import themeConfig from "../../../../configs/themeConfig"
import classnames from "classnames"

import {
    Button,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap"

import loginImg from "../../../../assets/img/pages/login.png"
import "../../../../assets/scss/pages/authentication.scss"
import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"

const LazyFormLogin = lazy(() => import("./FormLogin.js"))

class Layout extends React.Component {

    render() {
        return (
            <div
                className={classnames(
                    "full-layout wrapper blank-page dark-layout",
                    {
                        "layout-dark": themeConfig.layoutDark
                    }
                )}
            >
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="content-body">
                            <div className="flexbox-container">
                                <main className="main w-100">
                                    <Row className="m-0 justify-content-center">
                                        <Col
                                            sm="8"
                                            xl="7"
                                            lg="10"
                                            md="8"
                                            className="d-flex justify-content-center"
                                        >
                                            <Card className="bg-authentication login-card rounded-0 mb-0 w-100">
                                                <Row className="m-0">
                                                    <Col
                                                        lg="6"
                                                        className="d-lg-block d-none text-center align-self-center px-1 py-0"
                                                    >
                                                        <img src={loginImg} alt="loginImg"/>
                                                    </Col>
                                                    <Col lg="6" md="12" className="p-0">
                                                        <Card className="rounded-0 mb-0 px-2">
                                                            <CardBody>
                                                                <h4>Login</h4>
                                                                <p>Bienvenido, ingresa tus credenciales para acceder.</p>
                                                                <LazyFormLogin path={this.props.path}/>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Layout
