import React from "react"
import zxcvbn from 'zxcvbn'

import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    Label
} from "reactstrap"
import resetImg from "../../../../assets/img/pages/reset-password.png"
import "../../../../assets/scss/pages/authentication.scss"
import classnames from "classnames";
import themeConfig from "../../../../configs/themeConfig";
import "../../../../components/@vuexy/rippleButton/RippleButton"

class EditPassword extends React.Component {
    constructor(props) {
        super(props);
        //Global component variables
        this.state = {
            fields: {password: '', c_password: ''},
            errors_password: '',
            class_error: '',
            form_state: false,
            first_edit: false,
            score: "null"
        };

    }

    //Handle error sam epassword and strength password
    handleValidationField(fieldName, value) {
        let errors = '';
        let formIsValid = true;
        let class_errors = '';


        switch (fieldName) {
            case 'password':
                errors = {errors_password: ''};
                var pw = zxcvbn(value['password']);

                //Check Strength password
                if (value['password'] === "") {
                    this.setState({
                        score: "null"
                    });
                } else {
                    this.setState({
                        score: pw.score
                    });
                }

                //Check equal password
                if (this.state.first_edit == true) {
                    if (value['password'] === value['c_password']) {
                        if (pw.score >= 3) {
                            formIsValid = true;
                            errors["password"] = ''
                            class_errors = ''
                        } else {
                            formIsValid = false;
                            errors["password"] = "Contraseña un poco debil";
                            class_errors = 'is-invalid'
                        }
                    } else {
                        formIsValid = false;
                        errors["password"] = "Contraseña no coincide :(";
                        class_errors = 'is-invalid'

                    }

                    this.setState({form_state: formIsValid});
                    this.setState({errors_password: errors});
                    this.setState({class_error: class_errors});

                }
                return formIsValid;

            case 'c_password':
                errors = {errors_password: ''};
                var pw = zxcvbn(value['c_password']);

                //Check Strength password
                if (value['c_password'] === "") {

                    this.setState({
                        score: pw.score
                    });
                } else {

                    this.setState({
                        score: pw.score
                    });
                }

                //Check equal password
                if (value['password'] === value['c_password']) {
                    if (pw.score >= 3) {
                        formIsValid = true;
                        errors["password"] = ''
                        class_errors = ''
                    } else {
                        formIsValid = false;
                        errors["password"] = "Contraseña un poco debil";
                        class_errors = 'is-invalid'
                    }
                } else {
                    formIsValid = false;
                    errors["password"] = "Contraseña no coincide :(";
                    class_errors = 'is-invalid'

                }

                this.setState({first_edit: true});
                this.setState({form_state: formIsValid});
                this.setState({errors_password: errors});
                this.setState({class_error: class_errors});

                return formIsValid;
            default:
                break;
        }
    }

    //Value change inputs
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields},
            () => {
                this.handleValidationField(field, fields)
            });
    }

    render() {
        return (
            <div
                className={classnames(
                    "full-layout wrapper blank-page dark-layout",
                    {
                        "layout-dark": themeConfig.layoutDark
                    }
                )}
            >
                <div className="app-content">
                    <div className="content-wrapper">
                        <div className="content-body">
                            <div className="flexbox-container">
                                <main className="main w-100">
                                    <Row className="m-0 justify-content-center">
                                        <Col
                                            sm="8"
                                            xl="7"
                                            lg="10"
                                            md="8"
                                            className="d-flex justify-content-center"
                                        >
                                            <Card className="bg-authentication rounded-0 mb-0 w-100">
                                                <Row className="m-0">
                                                    <Col
                                                        lg="6"
                                                        className="d-lg-block d-none text-center align-self-center px-5"
                                                    >
                                                        <img className="px-5 mx-2" src={resetImg} alt="resetImg"/>
                                                    </Col>
                                                    <Col lg="6" md="12" className="p-0">
                                                        <Card className="rounded-0 mb-0 px-2 py-50">
                                                            <CardHeader className="pb-1 pt-1">
                                                                <CardTitle>
                                                                    <h4 className="mb-0">Nueva Contraseña</h4>
                                                                </CardTitle>
                                                            </CardHeader>
                                                            <p className="px-2 auth-title">
                                                                Ingresa por favor tu nueva contraseña.
                                                            </p>
                                                            <CardBody className="pt-1">
                                                                <FormGroup className="form-label-group">
                                                                    <Input
                                                                        type="password"
                                                                        name="collect_user[password]"
                                                                        placeholder="Contraseña"
                                                                        onChange={this.handleChange.bind(this, "password")}
                                                                        value={this.state.value}
                                                                        required
                                                                    />
                                                                    <Label>Contraseña</Label>
                                                                </FormGroup>
                                                                <FormGroup className="form-label-group">
                                                                    <Input
                                                                        className={this.state.class_error}
                                                                        type="password"
                                                                        name="collect_user[password_confirmation]"
                                                                        placeholder="Confirmar Password"
                                                                        onChange={this.handleChange.bind(this, "c_password")}
                                                                        value={this.state.value}
                                                                        required
                                                                    />
                                                                    <Label>Confirmar Password</Label>
                                                                    <div
                                                                        className="invalid-feedback">{this.state.errors_password["password"]}
                                                                    </div>
                                                                    <span className="passwordzxcvbn__strength" data-score={this.state.score} />

                                                                </FormGroup>
                                                                <div
                                                                    className="d-flex justify-content-between flex-wrap flex-sm-row flex-column">
                                                                    <Button.Ripple
                                                                        color="primary"
                                                                        outline
                                                                        className="px-75 btn-block"
                                                                        onClick={e => {
                                                                            e.preventDefault()
                                                                            window.location.replace(`${this.props.path}/login`);
                                                                        }}
                                                                    >
                                                                        Acceder a la plataforma
                                                                    </Button.Ripple>
                                                                    <Button.Ripple
                                                                        color="primary"
                                                                        type="submit"
                                                                        className="px-75 btn-block"

                                                                    >
                                                                        Enviar
                                                                    </Button.Ripple>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditPassword
