import React from "react"
import * as Icon from "react-feather"
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as BS from "react-icons/bs";
import * as AI from "react-icons/ai";
import * as GR from "react-icons/gr";


window.$app_path = document.getElementById('root').dataset.attrs
window.$app_company_type = document.getElementById('root').dataset.type
window.$app_machine_count = document.getElementById('root').dataset.machines
window.$app_services = document.getElementById('root').dataset.services

const company_type = window.$app_company_type == 1 ? 'admin' : ''
const services = window.$app_services.split(',')

const serviceArry = []

// Array to dashboard statistics
for (const [index, value] of services.entries()) {
    serviceArry.push({
        id: value == 1 ? 'dashdeliveries' : 'dashrefunds',
        title: value == 1 ? 'Entregas' : 'Devoluciones',
        type: "item",
        icon: value == 1 ? <Icon.Truck size={20}/> : <RI.RiRefundLine size={20}/>,
        permissions: ["admin", "staff", "user", "runner"],
        navLink: `${window.$app_path}/${services.length === 1 ? '' : (value == 1 ? '' : 'dashboard-refunds')}`
    })
}
const navigationConfig = [
    {
        id: "home",
        title: "Dashboard",
        type: "collapse",
        icon: <AI.AiOutlineDashboard size={20}/>,
        permissions: ["admin", "staff", "user", "runner"],
        badge: "success",
        badgeText: `${services.length}`,
        children: serviceArry
    },
    {
        id: "brands",
        title: "Marcas",
        type: "item",
        icon: <RI.RiPriceTag3Line size={20}/>,
        permissions: [`${company_type}`],
        navLink: `${window.$app_path}/brands/list`
    },
    {
        id: "users",
        title: "Usuarios",
        type: "item",
        icon: <Icon.User size={20}/>,
        permissions: ["admin", "staff"],
        navLink: `${window.$app_path}/users/list`
    },
    {
        id: "machines",
        title: "Lockers",
        type: "item",
        icon: <BI.BiStoreAlt size={20}/>,
        permissions: ["admin", "staff", "user", "runner"],
        badge: "success",
        badgeText: `${window.$app_machine_count}`,
        navLink: `${window.$app_path}/machines`
    },
    {
        id: "tutorials",
        title: "Tutoriales",
        type: "item",
        icon: <BS.BsCameraVideo size={20}/>,
        permissions: ["admin", "staff", "user", "runner"],
        navLink: `${window.$app_path}/tutorials`
    },
    {
        id: "settings",
        title: "Ajustes",
        type: "item",
        icon: <RI.RiSettings4Line size={20}/>,
        permissions: ["admin"],
        navLink: `${window.$app_path}/settings`
    }

]

for (const [index, value] of services.entries()) {
    navigationConfig.splice(4, 0, {
        id: value == 1 ? 'deliveries' : 'refunds',
        title: value == 1 ? 'Entregas' : 'Devoluciones',
        type: "item",
        icon: value == 1 ? <Icon.Truck size={20}/> : <RI.RiRefundLine size={20}/>,
        permissions: ["admin", "staff"],
        navLink: `${window.$app_path}/${value == 1 ? 'deliveries' : 'refunds'}`
    })
}


export default navigationConfig
