import React from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap"
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"

import axios from "axios"
import * as Icon from "react-feather"
import {history} from "../../../components/App/history";

var path = document.getElementById('root').dataset.attrs;


const UserDropdown = props => {
    return (
        <DropdownMenu right>
            <DropdownItem tag="a" onClick={() => history.push(`${path}/perfil`)}>
                <Icon.User size={14} className="mr-50"/>
                <span className="align-middle">Editar Perfil</span>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem tag="a" rel="nofollow" data-method="delete" href={`${path}/signout`}>
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">Cerrar Sesión</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {
        navbarSearch: false,
        suggestions: []
    }

    componentDidMount() {
        axios.get(`${path}/api/main-search/data`).then(({data}) => {
            this.setState({suggestions: data.searchResult})
        })
    }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        })
    }


    render() {
        let brand_role

        if (this.props.brandName === null) {
            brand_role = <span className="user-status">{this.props.userRole}</span>

        } else {
            if (window.$app_company_type == 1) {
                brand_role = <span className="user-status"><b>{this.props.brandName}</b> | {this.props.userRole}</span>
            } else {
                brand_role = <span className="user-status">{this.props.userRole}</span>
            }
        }

        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">

                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
                            {brand_role}
                        </div>
                        <span data-tour="user">
                <Avatar className="mr-1" content={this.props.userAvatar}/>
            </span>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </UncontrolledDropdown>
            </ul>
        )
    }
}

export default NavbarUser
