import React, {Suspense, lazy} from "react"
import {Router, Switch, Route} from "react-router-dom"
import Spinner from "../@vuexy/spinner/Loading-spinner"
import {connect} from "react-redux"
import {history} from "../App/history"
import {ContextLayout} from "../../utility/context/Layout"

var path = document.getElementById('root').dataset.attrs;



// Route-based code splitting

const home = lazy(() =>
    import("../../views/pages/Home")
)

const dashboardRefunds = lazy(() =>
    import("../../views/dashboard/refunds/Dashboard")
)

//Account setting
const accountSettings = lazy(() =>
    import("../../views/pages/account-settings/AccountSettings")
)

//Company setting
const companySettings = lazy(() =>
    import("../../views/pages/company-settings/CompanySettings")
)

//CC Users
const userList = lazy(() => import("../../views/apps/user/list/List"))

//Brand
const brandList = lazy(() => import("../../views/apps/brand/list/List"))

//Machines
const machineList = lazy(() => import("../../views/apps/machine/Machines"))

//Slots
const slotList = lazy(() => import("../../views/apps/slot/list/List"))

//Deliveries
const deliveryList = lazy(() => import("../../views/apps/delivery/list/List"))

//Refunds
const refundList = lazy(() => import("../../views/apps/refund/list/List"))

//Tutorials video
const tutorials = lazy(() => import("../../views/pages/tutorials/TutorialMain"))



//Misc
const comingSoon = lazy(() => import("../../views/pages/misc/ComingSoon"))
const error404 = lazy(() => import("../../views/pages/misc/error/404"))
const error500 = lazy(() => import("../../views/pages/misc/error/500"))
const authorized = lazy(() => import("../../views/pages/misc/NotAuthorized"))
const maintenance = lazy(() => import("../../views/pages/misc/Maintenance"))


// Set Layout and Component Using App Route
const RouteConfig = ({
                         component: Component,
                         fullLayout,
                         permission,
                         user,
                         ...rest
                     }) => (
    <Route
        {...rest}
        render={props => {
            return (
                <ContextLayout.Consumer>
                    {context => {
                        let LayoutTag =
                            fullLayout === true
                                ? context.fullLayout
                                : context.state.activeLayout === "horizontal"
                                ? context.horizontalLayout
                                : context.VerticalLayout
                        return (
                            <LayoutTag {...props} permission={props.user} >
                                <Suspense fallback={<Spinner/>}>
                                    <Component {...props} />
                                </Suspense>
                            </LayoutTag>
                        )
                    }}
                </ContextLayout.Consumer>
            )
        }}
    />
)
const mapStateToProps = state => {
    return {
        user: "editor"
    }
}

const AppRoute = connect(mapStateToProps)(RouteConfig)

class AppRouter extends React.Component {
    render() {
        return (
            // Set the directory path if you are deploying in sub-folder
            <Router history={history}>
                <Switch>
                    <AppRoute
                        exact
                        path={`${path}/`}
                        component={home}
                    />

                    <AppRoute
                        exact
                        path={`${path}/dashboard-refunds`}
                        component={dashboardRefunds}
                    />

                    <AppRoute
                        path={`${path}/perfil`}
                        component={accountSettings}
                    />


                    <AppRoute
                        path={`${path}/settings`}
                        component={companySettings}
                    />

                    <AppRoute
                        path={`/users/list`}
                        component={userList}/>

                    <AppRoute
                        path={`/brands/list`}
                        component={brandList}/>

                    <AppRoute
                        path={`/machines`}
                        component={machineList}/>

                    <AppRoute
                        path={`/slots`}
                        component={slotList}/>

                    <AppRoute
                        path={`/deliveries`}
                        component={deliveryList}/>

                    <AppRoute
                        path={`/refunds`}
                        component={refundList}/>

                    <AppRoute
                        path={`/tutorials`}
                        component={tutorials}/>


                    <AppRoute
                        path={`${path}/misc/not-authorized`}
                        component={authorized}
                        fullLayout
                    />

                </Switch>
            </Router>
        )
    }
}

export default AppRouter
